// Back to top
#back-to-top {
  background: $primary;
  position: fixed;
  bottom: 15px;
  right: 15px;
  display: block;
  height: 35px;
  width: 35px;
  z-index: 1;
  transition: all .3s;
  cursor: pointer;
  display: none;
  border-radius: 50%;

  svg {
    transform: scale(1.5);
    transition: all .3s;
    position: relative;
    top: -3px;

    g {
      fill: $white;
      opacity: 1;
      transition: all .3s;
    }
  }

  &:hover, &:focus {
    background: transparent;

    svg {
      transform: scale(3);

      g {
        fill: $primary;
      }
    }
  }
}
