.products {
  .content {
    p, h1, h2 {
      max-width: none;
    }
  }

  .product {
    @media screen and (max-width: 1000px) {
      margin-bottom: 30px;
    }

    .product-right {
      display: flex;
      justify-content: center;
      margin-bottom: 50px;

      @media screen and (max-width: 1000px) {
        display: block;
        text-align: center;
      }

      .product-item {
        width: 200px;
        height: 200px;
        display: block;
        margin: 0px;
        float: left;
        position: relative;
        transition: all .3s;
        cursor: pointer;

        //border: 1px solid $grey;
        //border-left: none;
        //border-collapse: collapse;

        @media screen and (max-width: 1000px) {
          display: inline-block;
          margin: 10px;
          width: calc(25% - 22px);
        }

        @media screen and (max-width: 830px) {
          width: calc(33.33% - 22px);
        }

        @media screen and (max-width: 630px) {
          width: calc(50% - 22px);
        }

        @media screen and (max-width: 420px) {
          width: calc(100% - 22px);
        }

        &:after {
          content: "";
          position: absolute;
          top: 40px;
          left: 0;
          bottom: 40px;
          width: 1px;
          background-color: $primary;

          @media screen and (max-width: 1000px) {
            display: none;
          }
        }

        &:first-of-type {
          &:after {
            display: none;
          }
        }

        &.active {
          .product-details {
            top: 0;

            .product-details-close {
              display: block;
            }

            .product-details-overlay {
              bottom: 0;
            }
          }
        }

        .product-image {
          width: 160px;
          height: 160px;
          margin: 20px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          overflow: hidden;

          @media screen and (max-width: 1000px) {
            width: calc(100% - 40px);
          }

          img {
            opacity: 0;
          }
        }

        .product-title {
          position: absolute;
          top: -20%;
          left: 50%;
          border-radius: 4px;
          background-color: black;
          color: $white;
          padding: 10px 15px;
          text-align: center;
          opacity: 0;
          transform: translate(-50%, -50%) scale(.6);
          transition: all .3s;
        }

        .product-cat {
          width: 20px;
          height: 20px;
          overflow: hidden;
          background-color: $_grey;
          border-radius: 50%;
          position: absolute;
          top: 5px;
          right: 5px;
          text-align: center;
          padding: 10px;
          border: 5px solid white;
          transition: all .3s;

          svg {
            width: auto;
            height: 100%;
            fill: $primary;
            transition: all .3s;
          }
        }

        &:hover {
          //border: 1px solid rgba($white, .1);
          box-shadow: $shadow;

          .product-title {
            top: 50%;
            opacity: 1;
            transform: translate(-50%, -50%) scale(1);
          }

          .product-cat {
            background-color: $primary;

            svg {
              fill: $white;
            }
          }
        }

        &.active {
          position: relative;
          /*border: 5px solid $white;
          top: 120px;*/
        }
      }
    }

    .product-details {
      position: fixed;
      top: 100%;
      left: 0;
      bottom: 0;
      right: 0;
      overflow-y: auto;
      z-index: 10;
      padding: 20px 40px;
      transition: all .3s;

      .product-details-close {
        position: fixed;
        color: $white;
        top: 10px;
        right: 15px;
        font-size: 20px;
        z-index: 15;
        background-color: $primary;
        border-radius: 50%;
        padding: 10px;
        padding: 5px 13px;
        line-height: 26px;
        transition: all .3s;
        display: none;

        &:hover {
          background-color: $white;
          color: $primary;
        }
      }

      .product-details-overlay {
        background-color: rgba($black, .8);
        position: fixed;
        top: 0;
        left: 0;
        bottom: 100%;
        right: 0;
        transition: all .3s;
      }

      h1 {
        position: relative;
        z-index: 10;
        margin-top: 40px;
        color: $white;
      }

      .product-detail {
        width: 29%;
        display: inline-block;
        margin: 10px;
        position: relative;
        z-index: 15;
        background-color: $white;
        box-shadow: $shadow_;
        cursor: auto;
        overflow: hidden;

        @media screen and (max-width: 750px) {
          width: 45%;
        }

        @media screen and (max-width: 520px) {
          width: 90%;
        }

        .detail-image {
          width: 100%;
          height: 140px;
          margin: 20px auto;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;

          img {
            width: 100%;
            opacity: 0;
          }
        }

        .product-titleplus {
          border-top: 1px solid $grey;
          padding: 10px;
        }

        .product-download {
          border-top: 1px solid $grey;
          padding: 10px;
          background-color: $_grey;
          color: $black;
          cursor: pointer;
          display: block;
          transition: all .3s;
          position: relative;
          z-index: 15;
          text-decoration: none;
          cursor: pointer;

          svg {
            fill: $black;
            width: 20px;
            height: auto;
            position: relative;
            top: 4px;
            left: 10px;
            transition: all .3s;
          }

          &:hover {
            color: $white;
            background-color: $primary;

            svg {
              fill: $white;
            }
          }
        }

        &.download-ln {
          .download-title {
            height: 48px;
            overflow: hidden;

            .hide {
              height: 48px;
              border-top: 1px solid $grey;
              padding: 10px 10px;
              background-color: $_grey;
              color: $black;
              cursor: pointer;
              display: block;
              transition: all .3s;
              position: relative;
              z-index: 15;
              text-decoration: none;
              cursor: pointer;

              svg {
                fill: $black;
                width: 20px;
                height: auto;
                position: relative;
                top: 4px;
                left: 10px;
                transition: all .3s;
              }
            }

            .product-download {
              width: calc(50% - 20px);
              float: left;
            }

            &:hover {
              .hide {
                border-top: 0px solid $grey;
                padding: 0 10px;
                height: 0px;
              }
            }
          }
        }
      }
    }
  }
}
