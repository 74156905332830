body {
  background-color: $white;
  font-family: $font-family;
  line-height: $line-height;
  font-size: $font-size;
  overflow-y: auto;

  &.body-hidden {
    overflow-y: hidden;
  }

  @media screen and (max-width: 800px) {
    font-size: 15px;
  }

  @media screen and (max-width: 560px) {
    font-size: 14px;
  }
}

.icon {
  width: 100px;
  height: 20px;
  margin-bottom: 10px;

  svg {
    fill: $primary;
    height: 100%;
    width: auto;
  }
}

.color--primary {
  color: $primary;
}

.color--grey {
  color: $grey;
}

.divider {
  position: relative;
  margin-top: 30px;
  padding-top: 30px;

  &:before {
    content: "";
    display: block;
    height: 2px;
    width: 40px;
    background-color: $grey;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.btn {
  background-color: $primary;
  border-radius: 4px;
  color: $white;
  cursor: pointer;
  text-decoration: none;
  padding: 10px 15px;
  transition: all .3s;

  &:hover {
    background-color: $secondary;
  }
}

.link {
  color: $primary;
  text-decoration: none;

  &:hover {
    border-bottom: 1px solid $primary;
  }
}

.container-alt {
  background-color: $_grey;

}

.clearfix {
  &:after {
    display: block;
    content: "";
    clear: both;
  }
}

.right {
  float: right;
}

.benefits {
  li {
    &:before {
      content: "•";
      color: $primary;
      margin-right: 5px;
    }
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 65px 0;

  @media screen and (max-width: $l) {
    width: calc(100% - 40px);
    padding: 40px 20px;
  }

  .content {
    h1, h2, h3, p {
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .holder {
    margin: 40px auto 0 auto;

    .image {
      float: left;
      width: 40%;
      position: relative;

      img {
        position: absolute;
        top: 0;
        right: 0;
        max-height: 574px;
        box-shadow: $shadow;
      }

      @media screen and (max-width: 560px) {
        float: none;
        width: 100%;
        position: static;
        margin-bottom: 30px;

        img {
          position: static;
          max-height: none;
          width: 100%;
        }
      }
    }

    .image-alt {
      img {
        min-height: 0;
        height: 340px;

        @media screen and (max-width: 560px) {
          height: auto;
        }
      }
    }

    .text {
      float: right;
      width: 55%;
      margin-left: 5%;

      @media screen and (max-width: 560px) {
        float: none;
        width: 100%;
        margin-left: 0;
      }
    }
  }

  .quote {
    margin: 50px auto;
    max-width: 460px;
    color: $black;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    position: relative;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: .1;
      width: 150px;
      height: auto;
    }
  }
}

.margin--top {
  margin-top: 20px;
}

.quote-icon {
  position: relative;
  z-index: -1;

  svg {
    position: absolute;
    opacity: .05;
    width: 250px;
    height: auto;
    left: 160px;
    top: -20px;
  }
}

.full-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 350px;
  position: relative;

  h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 60px;
    font-weight: lighter;
    transform: translate(-50%, -50%);
    color: $white;
    text-shadow: 0 0 20px $black;
    width: 100%;
    text-align: center;
  }

  @media screen and (max-width: 800px) {
    height: 300px;

    h1 {
      font-size: 40px;
    }
  }

  @media screen and (max-width: 560px) {
    height: 250px;

    h1 {
      font-size: 30px;
    }
  }

  @media screen and (max-width: 400px) {
    height: 200px;

    h1 {
      font-size: 28px;
    }
  }
}
