// Colors general
$_grey: #f4f4f4;
$grey: #cccccc;
$grey_: #393939;
$black: #393939;
$white: #ffffff;

// Colors specific
$primary: #3f9800;
$secondary: orange;

// General
$corner: 12px;
$circle: 50%;

// Shadow
$_shadow: 0 0 40px $_grey;
$shadow: 0 0 40px $grey;
$shadow_: 0 0 40px $grey_;

// Font(s)
$font-family: "Karla", -apple-system, BlinkMacSystemFont, sans-serif;
$font-size: 16px;
$font-weight: normal;
$line-height: 26px;

// Media queries
$xs: "499px";
$s: "767px";
$m: "989px";
$l: "1299px";
$xl: "1699px";
