@font-face {
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/Karla-Regular.woff2) format("woff2");
}

@font-face {
  font-family: "Karla";
  font-style: italic;
  font-weight: 400;
  src: url(./fonts/Karla-Italic.woff2) format("woff2");
}

@font-face {
  font-family: "Karla";
  font-style: normal;
  font-weight: 700;
  src: url(./fonts/Karla-Bold.woff2) format("woff2");
}

@font-face {
  font-family: "Karla";
  font-style: italic;
  font-weight: 700;
  src: url(./fonts/Karla-BoldItalic.woff2) format("woff2");
}
