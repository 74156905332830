@charset "UTF-8";
.pageclip-form {
  position: relative;
}
.pageclip-form .pageclip-form__success {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
}
.pageclip-form .pageclip-form__success .pageclip-form__success__message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
}

@font-face {
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/Karla-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "Karla";
  font-style: italic;
  font-weight: 400;
  src: url(./fonts/Karla-Italic.woff2) format("woff2");
}
@font-face {
  font-family: "Karla";
  font-style: normal;
  font-weight: 700;
  src: url(./fonts/Karla-Bold.woff2) format("woff2");
}
@font-face {
  font-family: "Karla";
  font-style: italic;
  font-weight: 700;
  src: url(./fonts/Karla-BoldItalic.woff2) format("woff2");
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
}

h1 {
  margin-bottom: 40px;
  color: #3f9800;
  font-size: 30px;
  font-weight: normal;
}
@media screen and (max-width: 800px) {
  h1 {
    font-size: 25px;
  }
}
@media screen and (max-width: 560px) {
  h1 {
    font-size: 21px;
  }
}

h2 {
  margin-bottom: 5px;
  color: #393939;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
}
@media screen and (max-width: 800px) {
  h2 {
    font-size: 17px;
  }
}
@media screen and (max-width: 560px) {
  h2 {
    font-size: 16px;
  }
}

p {
  margin-bottom: 20px;
  color: #393939;
}

ul {
  list-style-type: none;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

body {
  background-color: #ffffff;
  font-family: "Karla", -apple-system, BlinkMacSystemFont, sans-serif;
  line-height: 26px;
  font-size: 16px;
  overflow-y: auto;
}
body.body-hidden {
  overflow-y: hidden;
}
@media screen and (max-width: 800px) {
  body {
    font-size: 15px;
  }
}
@media screen and (max-width: 560px) {
  body {
    font-size: 14px;
  }
}

.icon {
  width: 100px;
  height: 20px;
  margin-bottom: 10px;
}
.icon svg {
  fill: #3f9800;
  height: 100%;
  width: auto;
}

.color--primary {
  color: #3f9800;
}

.color--grey {
  color: #cccccc;
}

.divider {
  position: relative;
  margin-top: 30px;
  padding-top: 30px;
}
.divider:before {
  content: "";
  display: block;
  height: 2px;
  width: 40px;
  background-color: #cccccc;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
}

.btn {
  background-color: #3f9800;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
  padding: 10px 15px;
  transition: all 0.3s;
}
.btn:hover {
  background-color: orange;
}

.link {
  color: #3f9800;
  text-decoration: none;
}
.link:hover {
  border-bottom: 1px solid #3f9800;
}

.container-alt {
  background-color: #f4f4f4;
}

.clearfix:after {
  display: block;
  content: "";
  clear: both;
}

.right {
  float: right;
}

.benefits li:before {
  content: "•";
  color: #3f9800;
  margin-right: 5px;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 65px 0;
}
@media screen and (max-width: 1299px) {
  .container {
    width: calc(100% - 40px);
    padding: 40px 20px;
  }
}
.container .content h1, .container .content h2, .container .content h3, .container .content p {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
.container .holder {
  margin: 40px auto 0 auto;
}
.container .holder .image {
  float: left;
  width: 40%;
  position: relative;
}
.container .holder .image img {
  position: absolute;
  top: 0;
  right: 0;
  max-height: 574px;
  box-shadow: 0 0 40px #cccccc;
}
@media screen and (max-width: 560px) {
  .container .holder .image {
    float: none;
    width: 100%;
    position: static;
    margin-bottom: 30px;
  }
  .container .holder .image img {
    position: static;
    max-height: none;
    width: 100%;
  }
}
.container .holder .image-alt img {
  min-height: 0;
  height: 340px;
}
@media screen and (max-width: 560px) {
  .container .holder .image-alt img {
    height: auto;
  }
}
.container .holder .text {
  float: right;
  width: 55%;
  margin-left: 5%;
}
@media screen and (max-width: 560px) {
  .container .holder .text {
    float: none;
    width: 100%;
    margin-left: 0;
  }
}
.container .quote {
  margin: 50px auto;
  max-width: 460px;
  color: #393939;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  position: relative;
}
.container .quote svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.1;
  width: 150px;
  height: auto;
}

.margin--top {
  margin-top: 20px;
}

.quote-icon {
  position: relative;
  z-index: -1;
}
.quote-icon svg {
  position: absolute;
  opacity: 0.05;
  width: 250px;
  height: auto;
  left: 160px;
  top: -20px;
}

.full-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 350px;
  position: relative;
}
.full-image h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 60px;
  font-weight: lighter;
  transform: translate(-50%, -50%);
  color: #ffffff;
  text-shadow: 0 0 20px #393939;
  width: 100%;
  text-align: center;
}
@media screen and (max-width: 800px) {
  .full-image {
    height: 300px;
  }
  .full-image h1 {
    font-size: 40px;
  }
}
@media screen and (max-width: 560px) {
  .full-image {
    height: 250px;
  }
  .full-image h1 {
    font-size: 30px;
  }
}
@media screen and (max-width: 400px) {
  .full-image {
    height: 200px;
  }
  .full-image h1 {
    font-size: 28px;
  }
}

.header-holder {
  height: 100px;
  position: relative;
}
.header-holder .header {
  height: 60px;
  border-bottom: 1px solid #cccccc;
  padding: 20px 0;
  position: fixed;
  width: 100%;
  background-color: #ffffff;
  z-index: 2;
}
.header-holder .header .container {
  padding: 0;
}
.header-holder .header .container .logo {
  float: left;
}
.header-holder .header .container .logo img {
  height: 60px;
}
.header-holder .header .container .hamburger {
  position: absolute;
  top: 0;
  right: 0;
  height: 100px;
  width: 100px;
  display: none;
  cursor: pointer;
}
@media screen and (max-width: 800px) {
  .header-holder .header .container .hamburger {
    display: block;
  }
}
.header-holder .header .container .hamburger span {
  position: absolute;
  top: 33px;
  left: 50%;
  transform: translateX(-50%);
  width: 45px;
  height: 4px;
  background-color: #3f9800;
  transition: all 0.3s;
}
.header-holder .header .container .hamburger span:nth-of-type(2) {
  top: 46px;
}
.header-holder .header .container .hamburger span:nth-of-type(3) {
  top: 59px;
}
.header-holder .header .container .hamburger.open span:nth-of-type(1) {
  top: 50px;
  left: 23px;
  transform: rotate(45deg);
}
.header-holder .header .container .hamburger.open span:nth-of-type(2) {
  width: 0px;
}
.header-holder .header .container .hamburger.open span:nth-of-type(3) {
  top: 50px;
  left: 23px;
  transform: rotate(-45deg);
}
.header-holder .header .container .mainMenu {
  float: right;
  display: block;
}
@media screen and (max-width: 800px) {
  .header-holder .header .container .mainMenu {
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s;
  }
}
.header-holder .header .container .mainMenu li {
  display: inline-block;
}
.header-holder .header .container .mainMenu li.selected a {
  color: #3f9800;
}
.header-holder .header .container .mainMenu li a {
  text-decoration: none;
  color: #393939;
  display: block;
  line-height: 60px;
  padding: 0 20px;
  position: relative;
}
.header-holder .header .container .mainMenu li a:hover {
  color: #3f9800;
}
.header-holder .header .container .mainMenu li a:hover:after {
  content: "";
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #3f9800;
  opacity: 0.1;
  z-index: -1;
}
.header-holder .header .container .mainMenu.main-menu-open {
  position: fixed;
  top: 100px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  transition: all 0.3s;
}
.header-holder .header .container .mainMenu.main-menu-open li {
  display: block;
  text-align: center;
}
.header-holder .header.mobile .mainMenu {
  max-height: 600px;
  transition: all 0.3s;
}

.footer-holder {
  background-color: #3f9800;
  position: relative;
}
.footer-holder .container {
  padding: 40px 20px 20px;
}
.footer-holder .socials {
  text-align: center;
}
.footer-holder .socials .social {
  width: 40px;
  height: 40px;
  display: inline-block;
  background-color: #ffffff;
  border-radius: 50%;
  margin: 0 10px;
  transition: all 0.3s;
}
.footer-holder .socials .social svg {
  width: 42px;
  height: auto;
  position: relative;
  top: -1px;
  left: -1px;
}
.footer-holder .socials .social:hover {
  transform: scale(1.1);
}
.footer-holder .logo {
  display: block;
  width: 200px;
  margin: 0 auto 25px auto;
}
.footer-holder .logo img {
  width: 100%;
}
.footer-holder .mainMenu {
  text-align: center;
}
.footer-holder .mainMenu li {
  display: inline;
}
.footer-holder .mainMenu li.selected a {
  color: #ffffff;
}
.footer-holder .mainMenu li a {
  text-decoration: none;
  color: #1f4c00;
  display: inline;
  line-height: 60px;
  padding: 0 20px;
  position: relative;
}
@media screen and (max-width: 560px) {
  .footer-holder .mainMenu li a {
    line-height: 40px;
    display: block;
  }
}
.footer-holder .mainMenu li a:hover {
  color: #ffffff;
}
.footer-holder .mainMenu li a:hover:after {
  content: "";
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  opacity: 0.1;
  z-index: -1;
}

.disclaimer {
  background-color: #1f4c00;
  padding: 20px;
}
.disclaimer .disclaimerMenu {
  margin: 0 auto;
  text-align: center;
}
.disclaimer .disclaimerMenu li {
  display: inline-block;
}
@media screen and (max-width: 560px) {
  .disclaimer .disclaimerMenu li {
    display: block;
  }
}
.disclaimer .disclaimerMenu li a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.3);
  display: inline;
  line-height: 30px;
  padding: 0 20px;
  position: relative;
}
@media screen and (max-width: 560px) {
  .disclaimer .disclaimerMenu li a {
    display: block;
    line-height: 40px;
  }
}
.disclaimer .disclaimerMenu li a:hover {
  color: #ffffff;
}
.disclaimer .disclaimerMenu li.selected a {
  color: #ffffff;
}

.grid .grid-item-reference {
  width: 50%;
  float: left;
  position: relative;
  margin-bottom: 40px;
}
.grid .grid-item-reference .grid-reference {
  padding: 30px;
}
@media screen and (max-width: 650px) {
  .grid .grid-item-reference {
    width: 100%;
  }
}
.grid .grid-item {
  width: 50%;
  float: left;
  position: relative;
  height: 200px;
  margin-bottom: 40px;
}
@media screen and (max-width: 800px) {
  .grid .grid-item {
    width: 100%;
    float: none;
  }
}
.grid .grid-item .grid-content {
  display: block;
  cursor: pointer;
  padding: 30px;
  max-height: 160px;
  overflow: hidden;
  transition: all 0.3s;
  text-decoration: none;
}
.grid .grid-item .grid-content ul li {
  color: #393939;
}
.grid .grid-item .grid-content .plus {
  color: #3f9800;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 20px;
  opacity: 0;
  transition: all 0.3s;
}
.grid .grid-item .grid-content:hover {
  border-radius: 12px;
  box-shadow: 0 0 40px #cccccc;
}
.grid .grid-item .grid-content:hover .plus {
  opacity: 0.6;
}
.grid .grid-item .grid-content.active {
  background-color: #ffffff;
  box-shadow: 0 0 40px #cccccc;
  border-radius: 12px;
  max-height: 900px;
  position: absolute;
  z-index: 2;
}
.grid .grid-item .grid-content.active .plus {
  font-size: 24;
  font-weight: bold;
  opacity: 1;
  transform: rotate(45deg);
}
.grid .grid-item .grid-content:after {
  content: "";
  background-color: #ffffff;
  width: calc(100% - 20px);
  position: absolute;
  top: 100%;
  left: 10px;
  height: 20px;
}

.partners-mini {
  text-align: center;
}
.partners-mini .partner-grid {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 989px) {
  .partners-mini .partner-grid {
    display: block;
  }
}
.partners-mini .partner-grid a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
}
@media screen and (max-width: 989px) {
  .partners-mini .partner-grid a {
    margin: 20px;
  }
}

form input, form textarea {
  width: calc(100% - 30px);
  padding: 15px 12px;
  background-color: #ffffff;
  background-image: none;
  border: 2px solid #ccc;
  border-radius: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: all 0.15s;
  font-size: 16px;
  font-family: "Karla", -apple-system, BlinkMacSystemFont, sans-serif;
  margin-bottom: 15px;
  display: block;
}
form input:focus, form textarea:focus {
  border: 2px solid #3f9800;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
}
form textarea {
  resize: none;
  min-height: 110px;
}

.contact .holder {
  padding-left: 250px;
  padding-right: 250px;
  margin: 0 auto;
}
@media screen and (max-width: 1200px) {
  .contact .holder {
    padding-left: 100px;
    padding-right: 100px;
  }
}
@media screen and (max-width: 880px) {
  .contact .holder {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.contact .holder .col-left {
  float: left;
  width: 30%;
  padding: 30px;
  background-color: #f4f4f4;
}
@media screen and (max-width: 590px) {
  .contact .holder .col-left {
    float: none;
    width: calc(100% - 60px);
    margin-bottom: 30px;
    padding: 30px 30px 10px 30px;
  }
}
.contact .holder .col-left .icon {
  width: 20px;
  height: auto;
}
@media screen and (max-width: 590px) {
  .contact .holder .col-left .icon {
    position: relative;
  }
}
.contact .holder .col-left .icon svg {
  fill: #3f9800;
  width: 100%;
  height: auto;
}
@media screen and (max-width: 590px) {
  .contact .holder .col-left .icon svg {
    position: absolute;
    top: 0;
    left: 0;
  }
}
.contact .holder .col-left a {
  color: #393939;
  text-decoration: none;
}
.contact .holder .col-left a:hover {
  color: #3f9800;
}
@media screen and (max-width: 590px) {
  .contact .holder .col-left p {
    position: relative;
    top: -10px;
    left: 40px;
  }
}
.contact .holder .btn {
  font-size: 16px;
}
.contact .holder .col-right {
  float: right;
  padding-left: 30px;
  width: calc(70% - 90px);
}
@media screen and (max-width: 590px) {
  .contact .holder .col-right {
    float: none;
    width: auto;
    padding-left: 0;
  }
}

#back-to-top {
  background: #3f9800;
  position: fixed;
  bottom: 15px;
  right: 15px;
  display: block;
  height: 35px;
  width: 35px;
  z-index: 1;
  transition: all 0.3s;
  cursor: pointer;
  display: none;
  border-radius: 50%;
}
#back-to-top svg {
  transform: scale(1.5);
  transition: all 0.3s;
  position: relative;
  top: -3px;
}
#back-to-top svg g {
  fill: #ffffff;
  opacity: 1;
  transition: all 0.3s;
}
#back-to-top:hover, #back-to-top:focus {
  background: transparent;
}
#back-to-top:hover svg, #back-to-top:focus svg {
  transform: scale(3);
}
#back-to-top:hover svg g, #back-to-top:focus svg g {
  fill: #3f9800;
}

.products .content p, .products .content h1, .products .content h2 {
  max-width: none;
}
@media screen and (max-width: 1000px) {
  .products .product {
    margin-bottom: 30px;
  }
}
.products .product .product-right {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}
@media screen and (max-width: 1000px) {
  .products .product .product-right {
    display: block;
    text-align: center;
  }
}
.products .product .product-right .product-item {
  width: 200px;
  height: 200px;
  display: block;
  margin: 0px;
  float: left;
  position: relative;
  transition: all 0.3s;
  cursor: pointer;
}
@media screen and (max-width: 1000px) {
  .products .product .product-right .product-item {
    display: inline-block;
    margin: 10px;
    width: calc(25% - 22px);
  }
}
@media screen and (max-width: 830px) {
  .products .product .product-right .product-item {
    width: calc(33.33% - 22px);
  }
}
@media screen and (max-width: 630px) {
  .products .product .product-right .product-item {
    width: calc(50% - 22px);
  }
}
@media screen and (max-width: 420px) {
  .products .product .product-right .product-item {
    width: calc(100% - 22px);
  }
}
.products .product .product-right .product-item:after {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  bottom: 40px;
  width: 1px;
  background-color: #3f9800;
}
@media screen and (max-width: 1000px) {
  .products .product .product-right .product-item:after {
    display: none;
  }
}
.products .product .product-right .product-item:first-of-type:after {
  display: none;
}
.products .product .product-right .product-item.active .product-details {
  top: 0;
}
.products .product .product-right .product-item.active .product-details .product-details-close {
  display: block;
}
.products .product .product-right .product-item.active .product-details .product-details-overlay {
  bottom: 0;
}
.products .product .product-right .product-item .product-image {
  width: 160px;
  height: 160px;
  margin: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  overflow: hidden;
}
@media screen and (max-width: 1000px) {
  .products .product .product-right .product-item .product-image {
    width: calc(100% - 40px);
  }
}
.products .product .product-right .product-item .product-image img {
  opacity: 0;
}
.products .product .product-right .product-item .product-title {
  position: absolute;
  top: -20%;
  left: 50%;
  border-radius: 4px;
  background-color: black;
  color: #ffffff;
  padding: 10px 15px;
  text-align: center;
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.6);
  transition: all 0.3s;
}
.products .product .product-right .product-item .product-cat {
  width: 20px;
  height: 20px;
  overflow: hidden;
  background-color: #f4f4f4;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  right: 5px;
  text-align: center;
  padding: 10px;
  border: 5px solid white;
  transition: all 0.3s;
}
.products .product .product-right .product-item .product-cat svg {
  width: auto;
  height: 100%;
  fill: #3f9800;
  transition: all 0.3s;
}
.products .product .product-right .product-item:hover {
  box-shadow: 0 0 40px #cccccc;
}
.products .product .product-right .product-item:hover .product-title {
  top: 50%;
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}
.products .product .product-right .product-item:hover .product-cat {
  background-color: #3f9800;
}
.products .product .product-right .product-item:hover .product-cat svg {
  fill: #ffffff;
}
.products .product .product-right .product-item.active {
  position: relative;
  /*border: 5px solid $white;
  top: 120px;*/
}
.products .product .product-details {
  position: fixed;
  top: 100%;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;
  z-index: 10;
  padding: 20px 40px;
  transition: all 0.3s;
}
.products .product .product-details .product-details-close {
  position: fixed;
  color: #ffffff;
  top: 10px;
  right: 15px;
  font-size: 20px;
  z-index: 15;
  background-color: #3f9800;
  border-radius: 50%;
  padding: 10px;
  padding: 5px 13px;
  line-height: 26px;
  transition: all 0.3s;
  display: none;
}
.products .product .product-details .product-details-close:hover {
  background-color: #ffffff;
  color: #3f9800;
}
.products .product .product-details .product-details-overlay {
  background-color: rgba(57, 57, 57, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 100%;
  right: 0;
  transition: all 0.3s;
}
.products .product .product-details h1 {
  position: relative;
  z-index: 10;
  margin-top: 40px;
  color: #ffffff;
}
.products .product .product-details .product-detail {
  width: 29%;
  display: inline-block;
  margin: 10px;
  position: relative;
  z-index: 15;
  background-color: #ffffff;
  box-shadow: 0 0 40px #393939;
  cursor: auto;
  overflow: hidden;
}
@media screen and (max-width: 750px) {
  .products .product .product-details .product-detail {
    width: 45%;
  }
}
@media screen and (max-width: 520px) {
  .products .product .product-details .product-detail {
    width: 90%;
  }
}
.products .product .product-details .product-detail .detail-image {
  width: 100%;
  height: 140px;
  margin: 20px auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.products .product .product-details .product-detail .detail-image img {
  width: 100%;
  opacity: 0;
}
.products .product .product-details .product-detail .product-titleplus {
  border-top: 1px solid #cccccc;
  padding: 10px;
}
.products .product .product-details .product-detail .product-download {
  border-top: 1px solid #cccccc;
  padding: 10px;
  background-color: #f4f4f4;
  color: #393939;
  cursor: pointer;
  display: block;
  transition: all 0.3s;
  position: relative;
  z-index: 15;
  text-decoration: none;
  cursor: pointer;
}
.products .product .product-details .product-detail .product-download svg {
  fill: #393939;
  width: 20px;
  height: auto;
  position: relative;
  top: 4px;
  left: 10px;
  transition: all 0.3s;
}
.products .product .product-details .product-detail .product-download:hover {
  color: #ffffff;
  background-color: #3f9800;
}
.products .product .product-details .product-detail .product-download:hover svg {
  fill: #ffffff;
}
.products .product .product-details .product-detail.download-ln .download-title {
  height: 48px;
  overflow: hidden;
}
.products .product .product-details .product-detail.download-ln .download-title .hide {
  height: 48px;
  border-top: 1px solid #cccccc;
  padding: 10px 10px;
  background-color: #f4f4f4;
  color: #393939;
  cursor: pointer;
  display: block;
  transition: all 0.3s;
  position: relative;
  z-index: 15;
  text-decoration: none;
  cursor: pointer;
}
.products .product .product-details .product-detail.download-ln .download-title .hide svg {
  fill: #393939;
  width: 20px;
  height: auto;
  position: relative;
  top: 4px;
  left: 10px;
  transition: all 0.3s;
}
.products .product .product-details .product-detail.download-ln .download-title .product-download {
  width: calc(50% - 20px);
  float: left;
}
.products .product .product-details .product-detail.download-ln .download-title:hover .hide {
  border-top: 0px solid #cccccc;
  padding: 0 10px;
  height: 0px;
}