.grid {
  .grid-item-reference {
    width: 50%;
    float: left;
    position: relative;
    margin-bottom: 40px;

    .grid-reference {
      padding: 30px;
    }

    @media screen and (max-width: 650px) {
      width: 100%;
    }
  }

  .grid-item {
    width: 50%;
    float: left;
    position: relative;
    height: 200px;
    margin-bottom: 40px;

    @media screen and (max-width: 800px) {
      width: 100%;
      float: none;
    }

    .grid-content {
      display: block;
      cursor: pointer;
      padding: 30px;
      max-height: 160px;
      overflow: hidden;
      transition: all .3s;
      text-decoration: none;

      ul {
        li {
          color: $black;
        }
      }

      .plus {
        color: $primary;
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 20px;
        opacity: 0;
        transition: all .3s;
      }

      &:hover {
        border-radius: $corner;
        box-shadow: $shadow;

        .plus {
          opacity: .6;
        }
      }

      &.active {
        background-color: $white;
        box-shadow: $shadow;
        border-radius: $corner;
        max-height: 900px;
        position: absolute;
        z-index: 2;

        .plus {
          font-size: 24;
          font-weight: bold;
          opacity: 1;
          transform: rotate(45deg)
        }
      }

      &:after{
        content: "";
        background-color: $white;
        width: calc(100% - 20px);
        position: absolute;
        top: 100%;
        left: 10px;
        height: 20px;
      }
    }
  }
}
