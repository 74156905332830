.partners-mini {
  text-align: center;

  .partner-grid {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: $m) {
      display: block;
    }

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 20px;

      @media screen and (max-width: $m) {
        margin: 20px;
      }
    }
  }
}
