form {
  input, textarea {
    width: calc(100% - 30px);
    padding: 15px 12px;
    background-color: $white;
    background-image: none;
    border: 2px solid #ccc;
    border-radius: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    transition: all .15s;
    font-size: $font-size;
    font-family: $font-family;
    margin-bottom: 15px;
    display: block;

    &:focus {
      border: 2px solid $primary;
      box-shadow: 0 0 25px rgba(0,0,0,.2);
    }
  }

  textarea {
    resize: none;
    min-height: 110px;
  }
}

.contact {
  .holder {
    padding-left: 250px;
    padding-right: 250px;
    margin: 0 auto;

    @media screen and (max-width: 1200px) {
      padding-left: 100px;
      padding-right: 100px;
    }

    @media screen and (max-width: 880px) {
      padding-left: 0px;
      padding-right: 0px;
    }

    .col-left {
      float: left;
      width: 30%;
      padding: 30px;
      background-color: $_grey;

      @media screen and (max-width: 590px) {
        float: none;
        width: calc(100% - 60px);
        margin-bottom: 30px;
        padding: 30px 30px 10px 30px;
      }

      .icon {
        width: 20px;
        height: auto;

        @media screen and (max-width: 590px) {
          position: relative;
        }

        svg {
          fill: $primary;
          width: 100%;
          height: auto;

          @media screen and (max-width: 590px) {
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }

      a {
        color: $black;
        text-decoration: none;

        &:hover {
          color: $primary;
        }
      }

      p {
        @media screen and (max-width: 590px) {
          position: relative;
          top: -10px;
          left: 40px;
        }
      }
    }

    .btn {
      font-size: $font-size;
    }

    .col-right {
      float: right;
      padding-left: 30px;
      width: calc(70% - 90px);

      @media screen and (max-width: 590px) {
        float: none;
        width: auto;
        padding-left: 0;
      }
    }
  }
}
