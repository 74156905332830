* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
}

h1 {
  margin-bottom: 40px;
  color: $primary;
  font-size: 30px;
  font-weight: normal;

  @media screen and (max-width: 800px) {
    font-size: 25px;
  }

  @media screen and (max-width: 560px) {
    font-size: 21px;
  }
}

h2 {
  margin-bottom: 5px;
  color: $black;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;

  @media screen and (max-width: 800px) {
    font-size: 17px;
  }

  @media screen and (max-width: 560px) {
    font-size: 16px;
  }
}

p {
  margin-bottom: 20px;
  color: $black;
}

ul {
  list-style-type: none;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}
