.header-holder {
  height: 100px;
  position: relative;

  .header {
    height: 60px;
    border-bottom: 1px solid $grey;
    padding: 20px 0;
    position: fixed;
    width: 100%;
    background-color: $white;
    z-index: 2;

    .container {
      padding: 0;

      .logo {
        float: left;

        img {
          height: 60px;
        }
      }

      .hamburger {
        position: absolute;
        top: 0;
        right: 0;
        height: 100px;
        width: 100px;
        display: none;
        cursor: pointer;

        @media screen and (max-width: 800px) {
          display: block;
        }

        span {
          position: absolute;
          top: 33px;
          left: 50%;
          transform: translateX(-50%);
          width: 45px;
          height: 4px;
          background-color: $primary;
          transition: all .3s;

          &:nth-of-type(2) {
            top: 46px;
          }

          &:nth-of-type(3) {
            top: 59px;
          }
        }

        &.open {
          span {
            &:nth-of-type(1) {
              top: 50px;
              left: 23px;
              transform: rotate(45deg);
            }

            &:nth-of-type(2) {
              //top: 46px;
              width: 0px;
            }

            &:nth-of-type(3) {
              top: 50px;
              left: 23px;
              transform: rotate(-45deg);
            }
          }
        }
      }

      .mainMenu {
        float: right;
        display: block;

        @media screen and (max-width: 800px) {
          max-height: 0px;
          overflow: hidden;
          transition: all .3s;
        }

        li {
          display: inline-block;

          &.selected {
            a {
              color: $primary;
            }
          }

          a {
            text-decoration: none;
            color: $black;
            display: block;
            line-height: 60px;
            padding: 0 20px;
            position: relative;

            &:hover {
              color: $primary;

              &:after {
                content: "";
                width: 40px;
                height: 40px;
                border-radius: 50%;
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: $primary;
                opacity: .1;
                z-index: -1;
              }
            }
          }
        }

        &.main-menu-open {
          position: fixed;
          top: 100px;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: white;
          transition: all .3s;

          li {
            display: block;
            text-align: center;
          }
        }
      }
    }

    &.mobile {
      .mainMenu {
        max-height: 600px;
        transition: all .3s;
      }
    }
  }
}

.footer-holder {
  background-color: $primary;
  position: relative;

  .container {
    padding: 40px 20px 20px;
  }

  .socials {
    text-align: center;

    .social {
      width: 40px;
      height: 40px;
      display: inline-block;
      background-color: $white;
      border-radius: 50%;
      margin: 0 10px;
      transition: all .3s;

      svg {
        width: 42px;
        height: auto;
        position: relative;
        top: -1px;
        left: -1px;
      }

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .logo {
    display: block;
    width: 200px;
    margin: 0 auto 25px auto;

    img {
      width: 100%;
    }
  }

  .mainMenu {
    text-align: center;

    li {
      display: inline;

      &.selected {
        a {
          color: $white;
        }
      }

      a {
        text-decoration: none;
        color: darken($primary, 15%);
        display: inline;
        line-height: 60px;
        padding: 0 20px;
        position: relative;

        @media screen and (max-width: 560px) {
          line-height: 40px;
          display: block;
        }

        &:hover {
          color: $white;

          &:after {
            content: "";
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: $white;
            opacity: .1;
            z-index: -1;
          }
        }
      }
    }
  }
}

.disclaimer {
  background-color: darken($primary, 15%);
  padding: 20px;

  .disclaimerMenu {
    margin: 0 auto;
    text-align: center;

    li {
      display: inline-block;

      @media screen and (max-width: 560px) {
        display: block;
      }

      a {
        text-decoration: none;
        color: rgba($white, .3);
        display: inline;
        line-height: 30px;
        padding: 0 20px;
        position: relative;

        @media screen and (max-width: 560px) {
          display: block;
          line-height: 40px;
        }

        &:hover {
          color: $white;
        }
      }

      &.selected {
        a {
          color: $white;
        }
      }
    }
  }
}
